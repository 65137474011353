import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import { App } from "vue";

export const createBugsnag = (app: App) => {
  if (!process.env.VUE_APP_BUGSNAG) return;

  Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG || "12",
    plugins: [new BugsnagPluginVue()],
    onError: function (event) {
      event.addMetadata("eventData", {
        request: event.request,
        errors: event.errors,
      });

      for (const error in event.errors) {
        for (const entry in event.errors[error].stacktrace) {
          if (
            event.errors[error].stacktrace[entry].file.indexOf(
              "googletagmanager"
            ) > -1
          ) {
            return false;
          }
        }
      }

      return true;
    },
  });

  const plugin = Bugsnag.getPlugin("vue");

  if (plugin) app.use(plugin);
};

export default Bugsnag;
