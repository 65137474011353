const loadImage = (src?: string | null) => {
  return new Promise((resolve) => {
    if (!src) return resolve({ loaded: false });

    const loadingImage = new Image();

    loadingImage.onload = () => {
      resolve({ loaded: true });
    };

    loadingImage.onerror = () => {
      resolve({ loaded: false });
    };

    loadingImage.src = src;
  });
};

export default loadImage;
