import useBaseStore from "@/stores/base";

export const resolveRelativeUrl = (
  url: string | undefined | null,
  basePath?: string
): string | undefined => {
  if (!url) return;

  const store = useBaseStore();

  return new URL(url, basePath || store.content.host_url).toString();
};
