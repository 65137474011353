import { defineStore } from "pinia";

type State = {
  data: {
    [key: string]: any;
    type: string;
  } | null;
  title: string;
};

export const usePopupStore = defineStore("popup", {
  state: (): State => ({
    data: null,
    title: "",
  }),
  actions: {
    close() {
      this.data = null;
    },
    open({
      data,
      title,
    }: {
      data: { [key: string]: any; type: string };
      title: string;
    }) {
      this.data = data;
      this.title = title;
    },
  },
});

export default usePopupStore;
