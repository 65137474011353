import { defineRule } from "vee-validate";
import i18n from "./../translations";
import { required, email, url } from "@vee-validate/rules";
import { configure } from "vee-validate";

configure({
  generateMessage: (context) => {
    return i18n.global.t(`validations.${context.rule.name}`);
  },
});

defineRule("required_not_zero", (value) => {
  if (!value || value === 0) {
    return i18n.global.t("options.requiredError");
  }

  return true;
});

defineRule("required", required);
defineRule("email", email);
defineRule("url", url);
