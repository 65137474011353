<template>
  <div v-if="baseStore.content" id="app">
    <div class="topbar" :class="{ 'topbar--fixed': baseStore.showNav }">
      <a :href="baseStore.content.host_url" class="topbar__logo">
        <img
          v-if="baseStore.content.logo_url"
          :src="baseStore.content.logo_url"
          :alt="baseStore.content.site_title"
          width="280"
        />
      </a>
      <MainMenu />
    </div>

    <router-view />
  </div>
</template>

<script>
import "@/assets/sass/index.scss";
import { mapStores } from "pinia";
import MainMenu from "./components/MainMenu.vue";
import useBaseStore from "./stores/base";
import usePopupStore from "./stores/popup";
import useProductStore from "./stores/product";

export default {
  components: { MainMenu },
  computed: {
    ...mapStores(useBaseStore, usePopupStore, useProductStore),
  },
  created() {
    // document.addEventListener("mousemove", (e) => {
    //   console.log(e.clientY);
    // });

    // document.addEventListener("mouseleave", (e) => {
    //   if (e.clientY < 50) {
    //     alert("leave!");
    //   }
    // });

    document.addEventListener("keydown", (e) => {
      if (e.key === "s" && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        this.popupStore.open({
          title: this.$t("modal.titleSave"),
          data: {
            type: "save",
          },
        });
      }
      // if (e.key === "z" && (e.ctrlKey || e.metaKey)) {
      //   e.preventDefault();
      //   this.productStore.undo();
      // }
      // if (e.key === "y" && (e.ctrlKey || e.metaKey)) {
      //   e.preventDefault();
      //   this.productStore.redo();
      // }
    });
  },
};
</script>
