import useFeatureStore from "@/stores/feature";

export type Features = {
  [key: string]: string;
};

export class Feature {
  constructor(features: Features) {
    const featureStore = useFeatureStore();
    featureStore.restore();

    if (Array.isArray(features)) {
      features.forEach((feature) => {
        this.push(feature);
      });
    }
  }

  push({ name, value }: { name: string; value: string }) {
    console.log(`FEATURES: SET ${name} TO ${value}`);
    const featureStore = useFeatureStore();
    featureStore.features[name] = value;
  }

  save() {
    const featureStore = useFeatureStore();
    featureStore.save();
  }

  clear() {
    const featureStore = useFeatureStore();
    featureStore.clear();
  }
}
