import { Content } from "@/types/content";
import { Zone } from "@/types/zones";
import { defineStore } from "pinia";

type State = {
  activeZoneHoverId: number | null;
  content: Content;
  showHistory: boolean;
};

export const useBaseStore = defineStore("base", {
  state: (): State => ({
    activeZoneHoverId: 0,
    content: {} as Content,
    showHistory: false,
  }),
  actions: {
    changeHoverZone(zone: Zone | null) {
      this.activeZoneHoverId = zone?.id || null;
    },
    toggleHistory(status: boolean) {
      if (typeof status === "undefined") {
        this.showHistory = !this.showHistory;
      } else {
        this.showHistory = status;
      }
    },
  },
});

export default useBaseStore;
