export default (
  first: { [key: string]: any } | null,
  second: { [key: string]: any } | null,
  filter: string[] = []
) => {
  if (!first || !second) return [];

  const mismatchedItems: string[] = Object.entries(first).reduce(
    (currentItems, [name, value]) => {
      const newItems: string[] = [...currentItems];

      if (filter.indexOf(name) !== -1) return newItems;

      if (JSON.stringify(second[name]) !== JSON.stringify(value)) {
        newItems.push(name);
      }

      return newItems;
    },
    [] as string[]
  );

  // TODO: use array filter here!
  return mismatchedItems;
};
