
import { defineComponent, PropType } from "vue";
import { AlertTypes } from "./Alert.types";

export default defineComponent({
  props: {
    variant: {
      type: String as PropType<AlertTypes>,
      default: AlertTypes["WARNING"],
    },
    className: {
      type: null,
      default: null,
    },
  },
});
