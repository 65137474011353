
import { defineComponent } from "vue";
import { AlertTypes } from "./Alert/Alert.types";
import Alert from "./Alert/Alert.vue";

export default defineComponent({
  components: { Alert },
  props: {
    title: {
      type: String,
      required: true,
      default: null,
    },
    text: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      AlertTypes,
      open: false as boolean,
    };
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
  },
});
