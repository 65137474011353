import { parseDomain } from "parse-domain";

export const endpoint = (domain = window.location.hostname) => {
  const parsedDomain = parseDomain(domain);

  if (process.env.VUE_APP_FORCE_DEFAULT_API) {
    return process.env.VUE_APP_DEFAULT_API;
  } else if (!parsedDomain.domain) {
    return process.env.VUE_APP_DEFAULT_API;
  } else {
    const containsWWW = true;

    return `https://${containsWWW ? "www." : ""}${
      parsedDomain.domain
    }.${parsedDomain.topLevelDomains?.join(".")}/tse/v2/`;
  }
};

export default endpoint;
