import { createRouter, createWebHistory } from "vue-router";

export default createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/:product_id",
      name: "configurator",
      component: () => {
        return import("./views/CreateSign.vue");
      },
    },
    {
      path: "/:pathMatch(.*)*",
      name: "404",
      component: () => {
        return import("./views/404.vue");
      },
    },
  ],
});
