import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, renderSlot as _renderSlot, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "more-info__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SVGIcon = _resolveComponent("SVGIcon")!
  const _component_Alert = _resolveComponent("Alert")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["more-info", { 'more-info--open': _ctx.open }])
  }, [
    _createElementVNode("button", {
      class: "more-info__button",
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)), ["prevent"]))
    }, [
      _createElementVNode("span", null, _toDisplayString(_ctx.title), 1),
      _createVNode(_component_SVGIcon, { name: "arrow-down" })
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Alert, {
        variant: _ctx.AlertTypes.NOTICE
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, () => [
            _createTextVNode(_toDisplayString(_ctx.text), 1)
          ])
        ]),
        _: 3
      }, 8, ["variant"])
    ])
  ], 2))
}