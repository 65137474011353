import cloneObject from "@/helpers/cloneObject";
import { Model } from "@/types/model";
import { Template } from "@/types/template";
import { defineStore } from "pinia";
import axios from "axios";
import API from "@/api";
import i18n from "../../translations";
import compareObject from "@/helpers/compareObject";
import { usePopupStore } from "../popup";
import { Zone } from "@/types/zones";
import { Preview } from "@/types/previewResponse";
import loadImage from "@/helpers/loadImage";
const CancelToken = axios.CancelToken;

const cancelTokens = {
  product: CancelToken.source(),
  preview: CancelToken.source(),
};

const SOFTLOADING_TYPES = ["signDimension", "options", "content", "contentv4"];

type State = {
  baseProductId: string;
  loading: boolean;
  softloading: boolean;
  models: Model[];
  activeModel: number;
  initialized: boolean;
  template: Template;
  editZone: any;
  flipped: boolean;
  side: "front" | "back";
  unsavedChanges: boolean;
  orderLoading: boolean;
  loadingAnimations: boolean;
  changedZones: number[][];
};

export const useProductStore = defineStore("product", {
  state: (): State => ({
    baseProductId: "",
    loading: false,
    softloading: false,
    loadingAnimations: false,
    models: [],
    activeModel: 0,
    initialized: false,
    template: {} as Template,
    editZone: null,
    flipped: false,
    side: "front",
    unsavedChanges: false,
    orderLoading: false,
    changedZones: [],
  }),
  actions: {
    getZoneComponent(zoneId: number | string): { src: string } | undefined {
      if (!this.template.preview_file?.zone_components) return;

      const zoneData =
        this.template.preview_file?.zone_components[zoneId.toString()];

      if (!zoneData) return;

      return zoneData.component;
    },
    toggleSide() {
      this.side = this.side === "front" ? "back" : "front";
    },
    updateTemplate(template: Template | ((template: Template) => Template)) {
      if (typeof template === "function") {
        this.template = template(this.template);
      } else {
        this.template = template;
      }

      document.title = this.template.htmlTitle;
    },
    updateCurrentModel(payload: Partial<Model>) {
      this.models[this.activeModel] = {
        ...this.models[this.activeModel],
        ...payload,
      };
    },
    async resolveID(productID: string) {
      this.loading = true;

      const result = await API.get<Partial<Model>>(
        `/resolve-product/${productID}`
      ).catch((err) => {
        throw err;
      });

      this.loading = false;
      this.baseProductId = productID;

      return result.data;
    },
    async getPreview(mutateState: (state: Model) => Model) {
      if (!this.model) return;

      cancelTokens.preview.cancel();
      cancelTokens.preview = CancelToken.source();

      const newState = cloneObject(this.model);
      const _newState =
        typeof mutateState === "function" ? mutateState(newState) : newState;

      const result = await API.post<Preview>(
        "/product/preview",
        { ..._newState, handleContentV4: true },
        {
          cancelToken: cancelTokens.preview.token,
        }
      ).catch(() => {});

      return result?.data;
    },
    async getTemplate({
      withHistory = true,
      force = false,
      prevModel,
    }: {
      withHistory?: boolean;
      force?: boolean;
      prevModel?: Model;
    }) {
      if (!this.model) return;

      const comparedModel = prevModel ?? this.prevModel;

      const changes = compareObject(this.model, comparedModel, [
        "isFallback",
        "historyData",
      ]);

      if (changes.length === 0 && force === false) return;

      const changedZones = compareObject(
        this.model.contentv4,
        comparedModel?.contentv4 || {}
      );

      this.changedZones.push(changedZones.map((item) => Number(item)));

      this.updateCurrentModel({
        historyData: {
          ...this.model.historyData,
          changes,
        },
      });

      console.log(changes[0]);

      if (changes.length === 1 && SOFTLOADING_TYPES.includes(changes[0])) {
        this.loadingAnimations = false;
        this.softloading = true;
      } else {
        this.loadingAnimations = true;
        this.softloading = false;
        this.loading = true;
      }

      cancelTokens.product.cancel();
      cancelTokens.product = CancelToken.source();

      const model = {
        ...this.model,
        oldParams:
          withHistory === true && comparedModel ? comparedModel : undefined,
      };

      const result = await API.post<Template>(
        "/product",
        { ...model, handleContentV4: true },
        {
          cancelToken: cancelTokens.product.token,
        }
      ).catch(() => {});

      if (axios.isCancel(result) || !result?.data) {
        return null;
      }

      const { data: template } = result;

      this.updateTemplate(template);

      this.updateCurrentModel({
        historyData: {
          ...this.model.historyData,
          preview_image: template?.preview_file?.src,
          changes,
        },
        ...template.force_change,
      });

      if (template.exceptionMessage && this.initialized) {
        const popupStore = usePopupStore();
        popupStore.open({
          title: i18n.global.t("modal.titleDialog"),
          data: {
            type: "dialog",
            text: template.exceptionMessage,
          },
        });
      }

      if (this.initialized) {
        this.unsavedChanges = true;
      }

      this.initialized = true;

      if (template.preview_file?.src) {
        await loadImage(template.preview_file?.src);
      }

      this.loading = false;
      this.changedZones = [];
      this.loadingAnimations = true;
      this.softloading = false;

      return template;
    },
    async save() {
      this.unsavedChanges = false;
      return API.post("/store-product/", {
        ...this.model,
        handleContentV4: true,
        getvars: window.location.pathname,
      });
    },
    async order() {
      this.unsavedChanges = false;
      this.orderLoading = true;

      const response = await API.post("/order-product", {
        ...this.model,
      }).catch(() => {
        this.orderLoading = false;
      });

      return response;
    },
    async emailTo(values: { [key: string]: any }) {
      this.unsavedChanges = false;

      return API.post("/mail-product", {
        ...values,
      });
    },
    async updateProduct(productData: Model) {
      this.setProduct(productData);
      await this.getTemplate({ withHistory: true, force: true });
    },
    setProduct(productData: Model) {
      const models = [...this.models];
      const data: Model = {
        ...productData,
        historyData: {
          created_at: new Date().toString(),
          changes: [],
          preview_image: "",
        },
      };

      // remove redo items
      if (this.models.length - this.activeModel - 1 > 0) {
        const nrOfItemsToRemove = this.models.length - 1 - this.activeModel;
        const removeIndex = this.activeModel + 1;

        models.splice(removeIndex, nrOfItemsToRemove);
      }

      models.push(cloneObject(data));

      this.activeModel = models.length - 1;
      this.models = models;
    },
    addModel(setState: (state: Model) => Model) {
      if (!this.model) return;

      const newModel = cloneObject(this.model);
      const _newModel =
        typeof setState === "function" ? setState(newModel) : newModel;

      this.setProduct(_newModel);
      this.getTemplate({ withHistory: false });
    },
    undo() {
      if (this.activeModel >= 1 && this.model) {
        const compareModel = cloneObject(this.model);

        this.activeModel = this.activeModel - 1;
        this.getTemplate({
          prevModel: compareModel,
        });
      }
    },
    redo() {
      if (this.activeModel < this.models.length - 1) {
        this.activeModel = this.activeModel + 1;
        this.getTemplate({});
      }
    },
    historyTo(modelIndex: number) {
      if (
        modelIndex >= 0 &&
        modelIndex <= this.models.length - 1 &&
        this.activeModel !== modelIndex
      ) {
        this.activeModel = modelIndex;
        this.getTemplate({ withHistory: false });
      }
    },
  },
  getters: {
    /* returns model and zone info from zoneId */
    findZoneById(state) {
      return (zoneId: number) => {
        const model = this.model?.contentv4[zoneId];
        const data = state.template.zonesv4.find((zone) => zone.id === zoneId);

        if (!model || !data) return undefined;

        return cloneObject({
          model,
          data,
        });
      };
    },
    model(state): Model | null {
      const modelItem = state.models[state.activeModel];
      // force model to new object, prevent changing history
      return modelItem ? cloneObject(modelItem) : null;
    },
    image(state): Template["preview_file"] | null {
      return state.template?.preview_file;
    },
    hasBack(): Boolean {
      return Boolean(this.image?.backSrc || this.image?.backSrcV3);
    },
    zoneBySide(state): (side: "front" | "back") => Zone[] {
      return (side = "front") => {
        return state.template.zonesv4.filter((zoneItem) => {
          return zoneItem.side.indexOf(side) > -1;
        });
      };
    },
    zones(state) {
      return state.template.zonesv4;
    },
    prevModel(state) {
      if (state.activeModel >= 1) {
        return cloneObject(state.models[state.activeModel - 1]);
      }

      return null;
    },
    nextModel(state) {
      if (state.activeModel < state.models.length - 1) {
        return state.models[state.activeModel + 1];
      }

      return null;
    },
    redoCount(state) {
      return state.models.length - state.activeModel - 1;
    },
    undoCount(state) {
      return state.activeModel;
    },
  },
});

export default useProductStore;
