import { defineStore } from "pinia";

type State = {
  features: { [key: string]: any };
};

export const useFeatureStore = defineStore("feature", {
  state: (): State => ({ features: {} }),
  actions: {
    save() {
      window.localStorage.setItem("features", JSON.stringify(this.features));
    },

    restore() {
      const json = window.localStorage.getItem("features");

      if (!json) return;

      const data = JSON.parse(json);

      if (data instanceof Object) {
        this.features = data;
      }
    },

    clear() {
      this.features = {};
      this.save();
    },
  },
});

export default useFeatureStore;
