import axios from "axios";
import { nanoid } from "nanoid";
import endpoint from "./endpoint";

export const API = axios.create({
  baseURL: endpoint(),
  withCredentials: true,
  timeout: 20000,
  headers: {
    "X-Custom-Spid": nanoid(),
    "X-Custom-Origin": window.location.origin,
    "X-Custom-Path": window.location.pathname,
  },
});

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (axios.isCancel(error)) {
      Promise.resolve();
    } else if (error?.response?.status === 403) {
      window.alert("Er is een fout opgetreden: 403");
    } else {
      throw error;
    }

    return error;
  }
);

export default API;
