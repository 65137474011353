import "core-js/stable";
import "requestidlecallback-polyfill";
import "regenerator-runtime/runtime";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import API from "@/api";
import i18n from "./translations";
import "@/plugins/veevalidate";
import SVGIcon from "@/components/SVGIcon.vue";
import { Feature } from "./helpers/feature";
import { createPinia } from "pinia";
import useBaseStore from "./stores/base";
import { Content } from "@/types/content";
import { createBugsnag } from "./plugins/bugsnag";
import bindFilters from "./filters";

if (!window.dataLayer) {
  window.dataLayer = {
    push: (data: any) => {
      console.log("Mocking dataLayer", data);

      if (data.eventCallback) data.eventCallback();
    },
  };
}

if (!window.gtag) {
  window.gtag = (...args) => {
    window.dataLayer.push(args);
  };
}

if (!window.hj) {
  window.hj = (event, eventName) => {
    console.log("Mocking hotjar", { event, eventName });
  };
}

(async () => {
  const response = await API.get<Content>("setup/", {
    params: {
      getvars: window.location.pathname,
    },
  }).catch(() => {
    window.location.reload();
  });

  if (response?.data) {
    const { data } = response;

    i18n.global.mergeLocaleMessage("all", data.translations);

    const app = createApp(App);
    const pinia = createPinia();

    bindFilters(app);

    app.use(router);
    app.use(i18n);
    app.use(pinia);

    useBaseStore().content = data;

    window.features = new Feature(window.features as any);

    createBugsnag(app);

    app.component("SVGIcon", SVGIcon);

    app.directive("click-outside", {
      // @ts-ignore
      bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event: any) {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
      },
      // @ts-ignore
      unbind: function (el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
      },
    });

    app.mount("#app");
  }
})();
