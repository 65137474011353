
import axios from "axios";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    name: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      iconData: null as String | null,
    };
  },
  watch: {
    name: {
      immediate: true,
      handler() {
        this.load();
      },
    },
  },
  methods: {
    async load() {
      if (!window.ICONS) window.ICONS = {};

      if (!window.ICONS[this.name]) {
        window.ICONS[this.name] = axios
          .get(`${process.env.BASE_URL}icons/${this.name}.svg`)
          .catch(() => {});
      }

      const response = await window.ICONS[this.name];

      if (response?.data) {
        this.iconData = response.data;
      }
    },
  },
});
