import type { App } from "vue";
import { resolveRelativeUrl } from "./resolveRelativeUrl";

export const filters = (app: App) => {
  app.config.globalProperties.$filters = {
    resolveRelativeUrl,
  };
};

export default filters;
